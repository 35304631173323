import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Page from '../components/Page';

const Countries = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  padding: 30px 50px;
  align-items: center;
  justify-content: space-between;
`;

const CountryHeading = styled(Page.SeciontHeader)`
  text-align: center;
`;
const CountryCard = styled(Link)`
  min-width: 200px;
  max-width: 225px;
  margin: 10px;
  position: relative;
  text-align: center;
  min-height: 200px;
  margin-top: 40px;
`;
const CountryFlag = styled.img`
  width: 100%;
  border: 1px solid #f5f5f5;
  box-shadow: 2px 2px 2px 2px #d8d8d8;

  :hover {
    box-shadow: 1px 1px 1px 1px #6a6a6a;

  }
`;
const CountryTitle = styled.h3`
  position: absolute;
  bottom: 0;
  margin-top: 20px;
  width: 100%;
`;

const CountriesPage = ({ pageContext: context }) => {
  const { countries } = context;
  countries.sort((a, b) => {
    if (a.title > b.title) {
      return 1;
    }
    if (b.title > a.title) {
      return -1;
    }
    return 0;
  });

  const {
    worldBackground,
  } = useStaticQuery(
    graphql`
      query {
        worldBackground: file(relativePath: { eq: "shallow-focus-photo-of-world-globe-1098515.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <Layout>
      <SEO title="Countries" />
      <Page.Wrapper>

        <Page.Image img={worldBackground.childImageSharp.fluid.srcWebp} />
        <Page.Content top>
          <Page.Title>Countries</Page.Title>
          <p>
            Here is a list of all countries that are currently listed as
            &quot;soverign states&quot;.
          </p>
          <p>
            {' '}
            While we do our best to keep things updated,
            not every country on this list is recognized by every other country.
            However, we know that the bible tells us that there will be people from every
            <strong>tribe, tounge and nation</strong>
            {' '}
            in heaven. So we stive to pray for all peoples.
          </p>
        </Page.Content>
        <Page.Content full>
          <CountryHeading>Countries Alphabetically</CountryHeading>
          <Countries>
            {countries.map((country) => (
              <CountryCard to={`/countries/${country.slug}`} key={country.id}>
                <CountryFlag alt={country.title} src={country.featuredImage.sourceUrl} />
                <CountryTitle>{country.title}</CountryTitle>
              </CountryCard>
            ))}
          </Countries>
        </Page.Content>
      </Page.Wrapper>

    </Layout>
  );
};
CountriesPage.defaultProps = {
  pageContext: {
    countries: {},
  },
};

CountriesPage.propTypes = {
  pageContext: PropTypes.shape({
    countries: PropTypes.array.isRequired,
  }),
};

export default CountriesPage;
